export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';
export const SET_USERNAME_AND_PASSWORD = 'SET_USERNAME_AND_PASSWORD';
export const SET_ACCOUNT_DATA_FETCHING = 'SET_ACCOUNT_DATA_FETCHING';

export const SET_BILLING_PLAN_DATA = 'SET_BILLING_PLAN_DATA';
export const SET_BILLING_PLAN_FETCHING = 'SET_BILLING_PLAN_FETCHING';
export const SET_BILLING_PLAN_FETCHING_LIST = 'SET_BILLING_PLAN_FETCHING_LIST';

export const SET_ORDER_FETCHING = 'SET_ORDER_FETCHING';
export const SET_ORDER = 'SET_ORDER';
export const SET_AT_AUTH = 'SET_AT_AUTH';

export const SET_CONNECT_DATA = 'SET_CONNECT_DATA';
export const SET_CONNECT_FETCHING = 'SET_CONNECT_FETCHING';

export const SET_TRANSACTION_DATA = 'SET_TRANSACTION_DATA';
export const SET_TRANSACTION_FETCHING_LIST = 'SET_TRANSACTION_FETCHING_LIST';
export const SET_TRANSACTION_FETCHING = 'SET_TRANSACTION_FETCHING';
export const SET_TRANSACTION = 'SET_TRANSACTION';
