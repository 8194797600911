import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getTransactionsList, radiusAuth, setAtAuth} from "../redux/actions";
import TransactionsTable from "./Components/TransactionsTable";
import "./index.scss";
import ConnectSvg from "./RadiusConnectSvg";
import PaypalButtonsServer from "./Components/PaypalButtonsServer";
import {InfoCircleOutlined} from '@ant-design/icons';
import {useHistory} from "react-router-dom";
import {calcBalanceHelper, trafficBalHelper} from "../Components/helpers";
import moment from "moment";
import { Breadcrumb, Typography, Divider, Button } from 'antd';
import { useCookies } from 'react-cookie';
import { Link } from "@mui/material";


const { Title, Text } = Typography;


function NonPayedBlock() {
  const history = useHistory();
  return (
    <div className="non-payed">
      <InfoCircleOutlined style={{ fontSize: 20 }}/><p>Your Billing Plan is not active! Click <Link onClick={() => history.push("/billingplan")}>HERE</Link> to go to billing plans and choose a plan!</p>
    </div>
  )
}
export default function MainPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { item: accountData } = useSelector(({account}) => account);
  const { username, password } = useSelector(({account}) => account);
  // console.log("username from state: ", username);
  // console.log("password from state: ", password);
  const [cookies, setCookie] = useCookies(['user'])
  const [ connectData, setConnectData ] = useState(false);
  const { items: transactions } = useSelector(({transaction}) => transaction);
  useEffect(() => {
    dispatch(getTransactionsList())
  }, [dispatch])

  const checkIsActiveBillingPlan = () => {
    const bool = accountData.billings && accountData.billings.length !== 0;
    // console.log("checkIsActiveBillingPlan: ", bool);
    return !bool;
  }
  const billingPlanItems = () => {
    let data = accountData.billings;
    if (accountData.billings.length === 2 && accountData.billings[0].billingPlanId === accountData.billings[1].billingPlanId) {
      data = [accountData.billings[0]];
    }
    if (data.length > 0) {
      return (
        data.sort((a, b) => a.id > b.id ? 1 : -1).map((item) => {
          let cData = 0;
          if (item.billingPlanTypeId === 1 && item.currentData > 0) {
            if (item.currBP.billingUnit === "Gb") cData = (item.currentData / 1024 / 1024 / 1024).toFixed(2);
            if (item.currBP.billingUnit === "Mb") cData = (item.currentData / 1024 / 1024).toFixed(2);
          }
          return (
            <div className="main-page-block" key={item.id}>
              <h3 className="plan-heading">
                {item.billingPlanId === accountData.billingPlanId ? "Current Billing Plan" : "Next Billing Plan"}
              </h3>
              <div className="current-plan-item">
                <h3 className="cost-amnt margin10">${item.currBP.cost}<span>/mo.</span></h3>
                <p className="plan-name margin10">
                  {item.currBP.name}
                </p>
                <div className="plan-dets">
                  {(item.billingPlanTypeId === 1) && (
                    <p>Data limit: <span
                      className="value">{item.currBP.dataLimit} {item.currBP.billingUnit}</span>
                    </p>
                  )}
                  {(item.billingPlanTypeId === 2) && (
                    <p>Time limit: <span
                      className="value">{item.currBP.timeLimit} {item.currBP.billingUnit}</span>
                    </p>
                  )}
                  <p>Bandwidth Up: <span className="value">{item.currBP.planBandwidthUp}mbps</span></p>
                  <p>Bandwidth Down: <span className="value">{item.currBP.planBandwidthDown}mbps</span></p>
                  {item.currBP.specOffers && item.currBP.specOffers !== "" && (
                    <p>Special Offer: <span className="value">{item.currBP.specOffers}</span></p>
                  )}
                  {(item.billingPlanTypeId === 2 && item.dateFrom && item.dateTo) && (
                    <p>Dates: <span
                      className="value">{moment(item.dateFrom).format("YYYY-MM-DD")} - {moment(item.dateTo).format("YYYY-MM-DD")}</span>
                    </p>
                  )}
                  {/*item.billingPlanTypeId === 1 && (
                    <p>Remaining traffic: <span
                      className="value">{cData} {item.currBP.billingUnit}</span>
                    </p>
                  )*/}
                  {item.isPayed === false && (
                    <>
                      <Button className="change-paln-btn" variant={"contained"}
                              onClick={() => history.push("/billingplan")}>Change Plan</Button>
                      <br/>
                      <PaypalButtonsServer/>
                    </>
                  )}
                </div>
              </div>
            </div>
          )
        })
      )
    } else if (accountData.currBP) {
      const { currBP } = accountData;
      return (
        <div className="main-page-block">
          <h3 className="plan-heading">Current Billing Plan</h3>
          <div className="current-plan-item">
            <h3 className="cost-amnt">${currBP.cost}<span>/mo.</span></h3>
            <p className="plan-name">{currBP.name}</p>
            <p>Billing Unit: <span className="value">{currBP.billingUnit}</span></p>
            {currBP.billingPlanId === 1 && (
              <p>Limit: <span className="value">{currBP.dataLimit} {currBP.billingUnit}</span></p>
            )}
            {currBP.billingPlanId === 2 && (
              <p>Limit: <span className="value">{currBP.timeLimit} {currBP.billingUnit}</span></p>
            )}
            <p>Bandwidth Up: <span className="value">{currBP.planBandwidthUp}mbps</span></p>
            <p>Bandwidth Down: <span className="value">{currBP.planBandwidthDown}mbps</span></p>
            {currBP.specOffers && currBP.specOffers !== "" && (
              <p>Special Offer: <span className="value">{currBP.specOffers}</span></p>
            )}
            <Button 
              type="primary" 
              size="large" 
              className="change-paln-btn"
              onClick={() => history.push("/billingplan")}
            >
              Change Plan
            </Button>
            <br/>
            <PaypalButtonsServer/>
          </div>
        </div>
      )
    }
  }

  const connectSubmit = e => {
    e.preventDefault();
    // console.log("e: ", e);
    // alert("event: ", e);
    dispatch(setAtAuth(false));
    setCookie('user', null, { path: '/' });
    const form = document.getElementById('form');
    // console.log("form: ", form);
    // window.location.href = `http://captive.portal3/www/login.chi?username=${username}&password=${password}`;
    form.submit();
  }

  const credit = accountData && accountData.status && accountData.status === 'ACTIVE' ? true : false;
  // console.log("accountData: ", accountData);

  return (
    <div>
      <Breadcrumb
        style={{
          marginTop: 20,
          fontSize: '1rem',
        }}
        items={[
          {
            title: 'Dashboard',
          },
          {
            title: 'Home',
          },
        ]}
      />
      {accountData && (
        <>
          <div>
            <Title level={2} style={{ marginBottom: 5 }}>Dashboard</Title>
            <Text type="secondary" style={{ fontSize: 16 }}>
              View your current billing plan's bandwidth and data usage limits as well as the remaining bandwidth. Checkout all your payments below.
            </Text>
          </div>
          <Divider />
          {checkIsActiveBillingPlan() && <NonPayedBlock />}
          <div className="main-page-blocks-wrapper">
            {/*{getCurrentBP()}*/}
            {/*{getNextBP()}*/}
            {billingPlanItems()}
            {/* {accountData.currBP && accountData.billings.length > 0 && accountData.billings.filter(({isPayed}) => isPayed === true).length < 2 && (
              <div className="main-page-block">
                <h3>Pay ${getPayCost(accountData.billings)} now</h3>
                <PaypalButtonsServer />
              </div>
            )} */}
            <div className="main-page-block">
              <h3 
                className="plan-heading" 
                style={{ background: 'linear-gradient(135deg, #6253e1, #04befe)'}}
                >My Balance</h3>
              <h3 className="cost-amnt">${calcBalanceHelper(accountData)}</h3>
              <p className="plan-name margin10">Current Remaining Balance</p>
              <Divider style={{ marginBottom: 5 }} />
              <h3 className="cost-amnt">{trafficBalHelper(accountData)}</h3>
              <p className="plan-name" style={{ marginBottom: 36 }}>Current remaining bandwidth</p>
              {/*<div>Date till: 12.03.2022</div>*/}
            </div>
            <div className={ credit ? "main-page-block" : "main-page-block disabled-block"}>
            {/* <div className="main-page-block"> */}
              <h3 className="plan-heading">Connect to Radius server</h3>
              {!connectData ? (
                <div className="pfont">
                  <h3>Click below button to login to Wifi!</h3>
                  <p>You can login into the Wifi Network after payment by logging into the Radius Server using the button below.</p>
                  <form id="form" name="form" method="post" action="http://captive.portal3/www/login.chi">
                    <input type="hidden" name="userurl" value="https://google.com" required />
                    <input name="username" value={username} type="hidden" />
                    <input name="password" value={password} type="hidden" />
                    <input type="button" onClick={connectSubmit} disabled={!credit} className="wifi-login" name="button" value="Connect" />
                  </form>
                </div>
              ) : (
                <div>
                  <ConnectSvg />
                  <p className="connected">Connected</p>
                </div>
              )}
            </div>
          </div>


      </>
      )}
      { transactions && accountData && (
        <div className="main-transactions-block">
          <div className="transactions-block-table" style={{width: "100%", marginRight: "20px"}}>
            <h2 style={{ marginTop: "unset"}}>Last Payments</h2>
            <TransactionsTable items={transactions.length > 3 ? transactions.slice(0,3) : transactions} />
          </div>
        </div>
      )}
    </div>
  )
}