import { useCookies } from "react-cookie";
import { setAtAuth } from "../redux/actions";

const RenderOnAnonymous = ({ children }) => {
  const [cookies] = useCookies(['user']);

  const checkAuth = () => {
    if (cookies.user) {
      setAtAuth(true);
      return false;
    } else {
      return true;
    }
  }

  return checkAuth() ? children : null;
}

export default RenderOnAnonymous