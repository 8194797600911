import React, {useEffect} from "react";
import Router from "./Routes/AuthRoutes";
import { Layout, theme } from "antd";
import "./index.scss";
import {useDispatch} from "react-redux";
import {getAccountData, getBillingPlansList} from "../redux/actions";
import SideBar from "./SideBar";

const { Content } = Layout;

export default function AuthApp(){
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAccountData())
    dispatch(getBillingPlansList());
  }, [dispatch]);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout>
        <SideBar />
        <Content
          style={{
            padding: 24,
            margin: '20px 20px 70px 20px',
            minHeight: '90vh',
            // width: '50%',
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Router />
        </Content>
    </Layout>
  );
};
