import {
  SET_ACCOUNT_DATA,
  SET_ACCOUNT_DATA_FETCHING,
  SET_AT_AUTH,
  SET_CONNECT_DATA,
  SET_CONNECT_FETCHING,
  SET_USERNAME_AND_PASSWORD,
} from '../ActionTypes';
import request from "../util/requestWrapper";

export const setAtAuth = (val) => {
  return async (dispatch) => {
    dispatch({ type: SET_AT_AUTH, payload: val });
  }
}

export const setAccountData = (val) => {
  return async (dispatch) => {
    dispatch({ type: SET_ACCOUNT_DATA, payload: val });
  }
}

export const setUsernameAndPassword = (val) => {
  return async (dispatch) => {
    dispatch({ type: SET_USERNAME_AND_PASSWORD, payload: val });
  }
}

export const getAccountData = () => {
  return async (dispatch) => {
    dispatch({ type: SET_ACCOUNT_DATA_FETCHING, payload: true });
    try {
      const data = await request({ method: 'GET', url: `/api/me` });
      // console.log("get account data response: ", data);
      if (data) {
        dispatch({ type: SET_ACCOUNT_DATA, payload: data});
        dispatch({ type: SET_ACCOUNT_DATA_FETCHING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_ACCOUNT_DATA_FETCHING, payload: false });
      throw new Error(error.message);
    }
  };
};

export const radiusAuth = () => {
  return async (dispatch) => {
    dispatch({ type: SET_CONNECT_FETCHING, payload: true });
    try {
      const data = await request({ method: 'GET', url: `/api/auth` });
      if (data) {
        dispatch({ type: SET_CONNECT_DATA, payload: data });
        dispatch({ type: SET_CONNECT_FETCHING, payload: false });
      }
      return data;
    } catch (error) {
      dispatch({ type: SET_CONNECT_FETCHING, payload: false });
      throw new Error(error.message);
    }
  };
};

export const saveAccountData = ({ body }) => {
  return async (dispatch) => {
    dispatch({ type: SET_ACCOUNT_DATA_FETCHING, payload: true });
    try {
      const data = await request({ method: 'PATCH', url: `/api/me`, body: body });
      if (data) {
        dispatch({ type: SET_ACCOUNT_DATA, payload: data});
        dispatch({ type: SET_ACCOUNT_DATA_FETCHING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_ACCOUNT_DATA_FETCHING, payload: false });
      throw new Error(error.message);
    }
  };
};
