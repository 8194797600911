import {useDispatch, useSelector} from "react-redux";
import {setAtAuth} from "../redux/actions";
import { Button } from "antd";
import React from "react";
import logo from "../img/mesh_logo.png";
import axios from "axios";
import { apiHost } from "../redux/util/config";
import { useCookies } from "react-cookie";

const RenderOnAuthenticated = ({ children }) => {
  const dispatch = useDispatch();
  const {item: accountData} = useSelector(({account}) => account);
  const [cookies, setCookie] = useCookies(['user']);

  if(accountData && accountData.accountTypeId === 2) {
    return (
      <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center", flexFlow: "column" }}>
          <img alt="logo" style={{ width: "200px" }} src={logo} />
          <p className="lead">It seems you are Wisp</p>
          <div className="current-plan-item">
            <Button
              className="change-paln-btn"
              block="true"
              type="primary"
              onClick={() => {
                axios.post(`${apiHost}/logout/user`)
                .then(res => {
                  console.log("logout response: ", res.data);
                  dispatch(setAtAuth(false));
                  setCookie('user', null, { path: '/' });
                }).catch(err => console.log('logout error: ', err.response.data))
              }}>
              Logout
            </Button>
          </div>
        </div>
      </div>
    )
  }
  if (cookies.user) {
    return children
  }
  return null;
}

export default RenderOnAuthenticated