import React from "react";
import RenderOnAnonymous from "./RenderOnAnonymous";
import RenderOnAuthenticated from "./RenderOnAuthenticated";
import Welcome from "./Welcome";
import AuthApp from "./AuthApp";
import "./index.scss";


export default function App(){
  return (
    <div className="container">
      <RenderOnAnonymous>
        <Welcome/>
      </RenderOnAnonymous>
      <RenderOnAuthenticated>
        <AuthApp />
      </RenderOnAuthenticated>
    </div>
  );
};
